import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from '../../utils/theme';
import CookieConsent from 'react-cookie-consent';
// import '../layout.css';

import BackgroundImage from '../BackgroundImage/BackgroundImage';

const GlobalStye = createGlobalStyle`
  body{
    margin: 0;    
    font-family: 'Krona One';
    font-weight: 400;
    height:100%;    
    font-size: 26px;
  }

  *, *::before, *::after{
    box-sizing: border-box;
  } 

  ::-webkit-scrollbar {
  width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media._4k}{
    width:100vw;
    margin: 0 auto;
  }
`;

const BannerSpan = styled.span`
  font-size:15px;

${({ theme }) => theme.media.tablet}{
    width:100vw;
    margin: 0 auto;    
    font-size: 0.3em;    
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <StyledWrapper>
        <GlobalStye />
        {children}
      </StyledWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Zgoda" >
        <BannerSpan>Ta strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.</BannerSpan>
      </CookieConsent>
    </>
  </ThemeProvider>
)

export default Layout;
